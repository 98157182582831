.modal-backdrop {
  z-index: 13400;
  background: unset;
}

.modal {
  display: none; // This is for a modal which is defined in html
  z-index: 13500;
  font-size: 12px;

  &.show {
    background: var(--color-black-opacity-50);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
  }

  .modal-dialog {
    position: relative;
    top: 10%;

    &.modal-xl {
      top: 10%;
      max-width: 90%;
    }

    &.modal-sm {
      .modal-footer {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    &.modal-full {
      max-width: unset;
      width: 100vw;
      height: 100vh;
      top: 0;
      margin: 0;
      border-radius: 0;

      .modal-content {
        padding: 0;
        border-radius: 0;
        border: none;

        header {
          // height: 135px;
          position: sticky;
          top: 0;
          left: 0;
          background: var(--color-white);
          z-index: 1;
          width: 100%;
          margin: auto;

          max-width: 1310px;
          padding: 0 15px;
        }

        .modal-body {
          width: 100%;
          margin: auto;
          min-height: calc(100vh - 240px);

          max-width: 1310px;
          padding: 20px 15px;
        }

        footer {
          border-top: 1px solid var(--color-grey-80);
          position: sticky;
          bottom: 0;
          width: 100%;
          right: 0;
          background: var(--color-white);
          height: 105px;
          display: flex;

          .footer-content-container {
            width: 100%;
            margin: auto;

            max-width: 1310px;
            padding: 0 15px;
          }
        }
      }


    }

    &.modal-login {
      width: 350px !important;
    }

    .modal-content {

      .modal-header {
        padding-top: 8px;
        padding-bottom: 8px;
        border: none;

        .modal-title {
          font-weight: 500;
        }
      }

      .modal-header-destructive {
        border-top: 4px solid var(--color-red);
        background-color: var(--color-light-grey);
        padding: 0.6rem 1rem;
      }

      .modal-body {
        background-color: var(--color-white);
      }

      .modal-footer {
        background-color: var(--color-white);
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;

        button {
          margin: 0;
          &:not(:first-child) {
            margin: 0 0 0 10px !important;
          }
        }
      }
    }
  }

  &.fresh {
    .modal-header {
      padding-top: 24px !important;
      padding-bottom: 0 !important;
    }

    .modal-body {
      padding-top: 0;
    }

    h4.modal-title {
      font-size: 12px;
      color: var(--color-font-secondary);
      text-transform: uppercase;
    }

    .modal-footer {
      margin-top: 18px;
      border: none;
      border-radius: 0.3rem;

      button {
        width: 100%;
        padding: 0;
        height: 40px;
        text-align: center;
        justify-content: center;
      }
    }
  }
}

.termsOfServiceAndPrivacyPolicyDialog {
  top: 2% !important;
  max-width: 95%;
  margin: 0 auto;
  .modal-lg {
    width: 100%;
    margin: 0;
  }
}
