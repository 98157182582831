// TODO: Use scss and variable to override when we upgrade ag-grid license. The current version fails scss build.
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// most of css' are added when we are using ag-theme-bootstrap. We may need to clean.

.ag-theme-alpine {
  font-size: 14px;
  font-family: 'Inter' !important;
  border: none;
  border-radius: 8px;
  overflow: visible;
  height: 100% !important;
  --ag-row-hover-color: var(--color-baseline-100) !important;
  --ag-border-color: var(--color-grey-80) !important;
  --ag-control-panel-background-color: var(--color-grey-96) !important;
  --ag-data-color: var(--color-baseline-700) !important;

  :focus {
    outline: none;
  }

  .ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    --ag-internal-padded-row-height: 20px !important;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: 100%;
  }

  .ag-overlay-wrapper {
    &.ag-layout-normal {
      align-items: end;

      .ag-overlay-no-rows-center {
        margin-bottom: 40px;
      }
    }

    &.ag-layout-auto-height {
      align-items: center;
    }
  }

  .ag-column-drop-horizontal-icon::before {
    content: url('/assets/icons/line-icons/shared/black/drag.svg');
    vertical-align: middle;
  }

  .ag-icon-filter::before {
    content: url('/assets/icons/line-icons/shared/grey/filter.svg');
    zoom: 80%;
  }

  .ag-row-no-animation .ag-row {
    transition: unset;
  }

  .ag-ltr .ag-group-child-count {
    margin-left: 6px;
  }

  .ag-floating-filter-body {
    .ag-floating-filter-input > .ag-input-field > .ag-input-wrapper > input {
      padding-left: 1.6em;
      background: transparent;
      background-image: url('/assets/icons/line-icons/shared/grey/search.svg');
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: 0;
      border: none;
      border-bottom: solid 1px var(--color-grey-30);
      line-height: 1;
      height: 19px;
      border-radius: 0;
      margin-right: -11px;
      padding-right: 18px;

      &:focus {
        outline: none;
      }

      &[disabled] {
        background-image: unset;
        padding-left: 0;
        background-color: unset;
        border: none !important;
      }

      &[type='date'] {
        background-image: unset;
        padding-left: 3px;
      }
    }
  }

  input[class^=ag-][type=text]:focus,
  input[class^=ag-][type=number]:focus {
    outline: none !important;
    border-color: inherit;
    box-shadow: none;
  }

  background-color: var(--color-white);
  font-family: 'Inter' !important;

  .ag-row-selected::before {
    background: var(--color-grey-90) !important;
  }

  .ag-cell-range-selected-1:not(.ag-cell-focus),
  .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background: transparent !important;
    border: none;
  }

  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left,
  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right,
  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top,
  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom {
    border: none !important;
  }

  .ag-popup {
    z-index: 5;
  }

  .ag-icon {
    font-size: 12px;
  }

  .ag-overlay-loading-wrapper {
    background-color: unset;
  }

  .ag-menu {
    border-color: var(--color-grey-70);

    .ag-tab-header {
      border-color: var(--color-grey-80);
    }

    .ag-tab {
      &.ag-tab-selected {
        border-color: var(--color-grey-80);
        border-bottom-color: transparent;
      }
    }

    .ag-menu-separator > span {
      background-image: none;
      background-color: var(--color-grey-80);
      height: 1px;
    }
  }

  .ag-filter {
    .ag-filter-apply-panel {
      border-top: none;

      button {
        border-radius: 8px;
        padding: 2px 12px;
        border: none;
        color: var(--color-grey-20);
        background-color: var(--color-grey-70);
        transition: background-color .12s ease-in-out;

        &:hover {
          background-color: var(--color-grey-60);
        }
      }
    }

    .ag-filter-select {
      margin: 3px 7px 6px;
      width: calc(100% - 14px);

      &:focus {
        outline: none;
      }
    }
  }

  .ag-tool-panel-wrapper {
    border: 1px solid var(--color-grey-70) !important;
    border-radius: 6px;
    position: absolute;
    min-height: 600px;
    top: -12px;
    z-index: 6;

    .ag-column-panel-column-select {
      border: none;
    }

    .ag-column-drop,
    .ag-column-select-panel,
    .ag-column-select-header,
    .ag-column-select-list {
      border-bottom: none;
      background-color: var(--color-white);
    }

    .ag-filter-panel {
      background-color: var(--color-white);
      padding: 6px 0;

      .ag-filter-air {
        border-color: var(--color-grey-80);
      }

      .ag-filter-toolpanel-instance {
        background-color: var(--color-white);
        color: var(--color-black);
        font-weight: normal;

        .ag-filter-toolpanel-header {
          padding: 0 0 0 5px;
        }
      }
    }

    .ag-column-panel {
      .ag-column-drop {
        border-right: none;
      }
      .ag-primary-cols-header-panel,
      .ag-pivot-mode-panel {
        border-bottom: none;
        background-color: var(--color-white);
      }

      .ag-column-select-list {
        position: relative;

        &> .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--color-white);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 999;
          font-size: 14px;
          font-weight: 500;
          font-style: italic;
          color: var(--color-font-tertiary);
        }
      }

    }
  }
  .ag-primary-cols-header-panel{
    border-bottom: none;
  }

  .ag-body-viewport {
    background-color: var(--color-white);
    .ag-row-selected::before {
      background-color: var(--color-baseline-200) !important;
    }
  }

  .ag-root, .ag-root-wrapper {
    border: none;
  }

  .ag-root {
    min-height: 200px;
  }

  .ag-root-wrapper {
    overflow: visible;
  }

  .ag-menu {
    z-index: 9;
  }

  .ag-header-row:first-child {
    .ag-header-cell {
      padding-top: 3px;
    }
  }

  .ag-header-row {
    font-size: 12px !important;
    font-family: 'Inter' !important;
    color: var(--color-grey-25) !important;
    font-weight: 500 !important;
  }

  .ag-header {
    background-color: var(--color-white);
    border-bottom: none;

    .ag-header-viewport, .ag-pinned-left-header {
      .ag-header-cell-text {
        font-size: 12px !important;
        font-family: 'Inter' !important;
        color: var(--color-baseline-500) !important;
        font-weight: 500 !important;
      }

      .ag-header-row > .ag-header-cell {
        border: none;
        padding-bottom: 6px;
        padding-right: 0;

        .ag-floating-filter-button {
          margin-left: 0;
          margin-right: 8px;
          margin-bottom: 0px;
          padding-bottom: 3px;
          position: relative;
          right: 4px;
          top: 1.4px;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  .ag-column-drop-horizontal .ag-column-drop-empty-message {
    opacity: 1;
    color: var(--color-baseline-500);
  }
  
  .ag-column-drop-horizontal.ag-column-drop {
    border-radius: 8px;
    border: none;
  }

  .ag-column-drop {
    background: linear-gradient(to right, var(--color-baseline-50), var(--color-white) 50%);
    border-top: 1px solid var(--color-grey-80);
    border-right: 1px solid var(--color-grey-80);
  }

  .ag-pinned-left-cols-container:not(.ag-hidden) {
    border-right: none;
    z-index: 1;

    .ag-row {
      box-shadow: 6px 0px 5px -6px var(--color-baseline-300);
      border-radius: 8px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &.ag-row-hover::before, &.ag-row-selected::before {
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.row-with-tags {
        max-height: unset;
        border-top: 5px solid white;
        border-bottom: 5px solid white;

        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;

        &.ag-row-hover::before, &.ag-row-selected::before {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }
      }
    }

    &~ .ag-center-cols-clipper {
      .ag-row {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.ag-row-hover::before, &.ag-row-selected::before {
          border-radius: 8px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.row-with-tags {
          max-height: unset;
          border-top: 5px solid white;
          border-bottom: 5px solid white;

          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;

          &.ag-row-hover::before, &.ag-row-selected::before {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }
      }
    }
  }

  .ag-center-cols-container {
    .ag-row {
      &.row-with-tags {
        max-height: unset;
        border-top: 5px solid white;
        border-bottom: 5px solid white;
      }
    }
  }

  .ag-ltr .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned {
    border-right: none;
  }

  .ag-pinned-left-header {
    border-right: none;
  }

  .ag-pinned-right-header {
    border-bottom: none;
  }

  .ag-checkbox-input-wrapper {
    font-size: 20px;
    line-height: 20px;
    width: 20px;
    height: 20px;
  }

  .ag-side-bar-right {
    border: none !important;
  }

  .ag-side-bar {
    border: none;
    background: transparent;
  }

  .ag-rich-select {
    .ag-rich-select-value {
      .ag-icon-small-down {
        display: none;
      }
    }
  }

  .ag-row {
    border: none;
    max-height: 70px;
    border-radius: 8px;
    background-color: var(--color-baseline-50);

    &.ag-row-hover::before, &.ag-row-selected::before {
      border-radius: 8px;
    }
  }

  .checkboxCell {
    padding: 2px 10px 0 14px !important;
  }
  .showDetailCell {
    padding: 0 0 0 5px;
    span a i{
      text-decoration: none;
      padding: 3px 7px;
      font-size: 18px;
    }
  }

  .ag-date-filter .ag-input-wrapper input[type="date"] {
    background: none;
    border: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .ag-input-wrapper {
    input:not([type]),
    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="date"],
    input[type="datetime-local"] {
      border-color: var(--color-med-grey);
    }
  }

  .ag-floating-filter-body {
    oes-date-filter {
      input {
        background-image: unset;
      }
    }
  }

  .ag-filter {
    label.ag-set-filter-item {
      margin: 0;
    }

    input[type="text"] {
      padding-left: 7px;
    }

    .ag-filter-header-container {
      margin: 10px 0px 0;

      &:nth-child(2) {
        border-bottom: 1px solid var(--color-grey-80);
      }

      input.ag-filter-filter {
        margin: 0 9px;
        border: solid 1px var(--color-grey-70);

        &:focus {
          outline: none;
        }
      }
    }

    .ag-filter-body {
      padding: 1px 2px;

      input.ag-filter-filter {
        width: 96%;
        margin: 0 2%;
        height: 20px;
        border: solid 1px var(--color-grey-70);

        &:focus {
          outline: none;
        }
      }
    }
  }

  .ag-filter-toolpanel {
    .ag-filter-body-wrapper {
      background-color: var(--color-white);
    }

    .ag-filter-toolpanel-header {
      color: var(--grey-20);
      font-weight: normal;
      background-color: var(--color-white);
    }

    .ag-filter-toolpanel-group-level-0 {
      border: none;
    }

    .ag-filter-toolpanel-instance-filter {
      border-top-color: var(--color-grey-80);
      border-bottom-color: var(--color-grey-80);
      margin: 0;
    }

    .ag-filter-toolpanel-group-item {
      margin: 0;
    }

    .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after {
      content: '\f0b0';
      font-family: 'FontAwesome';
      color: var(--color-dark-grey);
      font-size: 12px;
      line-height: 12px;
    }
  }

  .ag-tool-panel-wrapper {
    background-color: var(--color-white);

    .ag-input-wrapper input {
      border: none;
      border-bottom: 1px solid var(--color-grey-60);
    }
  }

  .ag-picker-field-wrapper {
    border: 1px solid var(--color-grey-80);
    border-radius: 0;
  }


  .ag-cell {
    text-overflow: clip !important;
    vertical-align: middle;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    padding-right: 13px;

    a {
      color: var(--color-baseline-700) !important;
      text-decoration: underline;

      &.text-link--color-brand {
        color: var(--color-brand) !important;
      }
    }

    p {
      font-weight: 400;
      color: var(--color-baseline-500);
    }

    &.link {
      text-decoration: underline;
      cursor: pointer;
    }

    .company-logo {
      background-color: var(--color-white);
      border-radius: 6px;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .no-image {
      font-size: 20px;
      line-height: 64px;
      margin: 5px auto;
      width: 80px;
      color: var(--color-med-grey);
      text-align: center;
    }

    pre {
      display: flex;
      margin: 0;
      font-family: 'Inter';
      font-size: 14px;
      line-height: 1.5;
      color: var(--color-font-secondary);
      white-space: pre-line;
    }
  }

  .checkboxColumn {
    padding: 0 14px;
    span {
      float: unset;
    }
  }

  .checkboxCell {
    text-align: center;
    border: solid 1px transparent !important;
    padding-top: 2px;
    text-overflow: unset;

    span {
      margin: 0;
      float: unset;
    }
  }

  .ag-cell-value > span {
    line-height: 20px;
  }

  .ag-cell-inline-editing {
    height: 25px;
    padding: 0;

    input {
      height: 23px;
    }

    input[type="text"]:focus,
    input[type="tel"]:focus,
    input[type="date"]:focus,
    input[type="datetime-local"]:focus {
      border-bottom: 2px solid var(--color-med-grey);
    }

    select {
      height: 23px !important;
    }
  }


  .ag-floating-filter-button {
    float: unset;
    line-height: unset;
    margin: 0;

    button {
      height: auto;
    }
  }

  .ag-icon-none {
    background-image: none;

    &::before {
      zoom: 156%;
    }
  }

  .ag-icon-desc {
    background-image: none;

    &::before {
      zoom: 120%;
      margin-left: 2px;
    }
  }

  .ag-icon-asc {
    background-image: none;

    &::before {
      zoom: 120%;
      margin-left: 2px;

    }
  }

  .ag-icon-grip:before {
    opacity: .4;
  }

  select.ag-filter-select {
    height: 20px !important;
    &:focus {
      outline: 1px solid var(--color-dark-grey);
    }
  }

  .ag-overlay-loading-center {
    border: none !important;
    background-color: var(--color-dark-grey) !important;
    color: var(--color-white) !important;
    border-radius: 0 !important;
    padding: 10px 20px !important;
    font-size: 16px;
  }

  .ag-tool-panel {
    border-right: none;
  }

  .ag-status-bar {
    font-family: 'Inter';
    border: none;
    background-color: var(--color-grey-96);
    color: var(--color-grey-25);
  }

  .ag-status-name-value-value {
    font-weight: 400 !important;
    color: var(--color-grey-20) !important;
  }

  .ag-header-cell {
    border-top: none !important;
  }

  .ag-header-cell-sorted-none {
    .ag-sort-none-icon {
      display: block !important;
    }
  }

  .error {
    background-color: var(--color-light-red);
  }

  .read-only,
  .not-editable {
    color: var(--color-dark-grey);
    background-color: var(--color-grey-96);
    &.ag-cell-focus {
      outline: unset !important;
      border: unset !important;
    }
  }
  .required {
    background-color: var(--color-light-red);
  }

  .ag-cell-not-inline-editing {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;

    &.ag-cell-focus {
      border: none !important;
      outline: solid 1px transparent !important;
      &.no-focus {
        border: solid 1px transparent !important;
      }
      input.hasDatepicker {
        height: 23px;
      }
      &.ag-cell.checkboxCell {
        border: solid 1px transparent !important;
      }
    }
    &.ag-cell-with-height.checkboxCell {
      padding: 2px 5px !important;
    }
  }

  .ag-paging-page-summary-panel {
    button {
      background-color: var(--color-white);
      border: solid 1px var(--color-med-grey);
      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .ag-filter-condition {
    margin: 5px 0 0 0;
  }

  .ag-checkbox-input-wrapper > input{
    cursor: pointer;
  }

  .ag-checkbox-input-wrapper::after {
    border: 1px solid var(--color-grey-70);
    border-radius: 4px;
    font-family: "FontAwesome";
    content: '\f00c';
    color: var(--color-white);
    width: 20px;
    height: 20px;
    padding: 0px 0 0px;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    justify-content: center;
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    content: '\f00c';
    color: var(--color-grey-30);
  }

  .ag-checkbox-input-wrapper:focus-within, .ag-theme-alpine .ag-checkbox-input-wrapper:active {
    box-shadow: none;
  }

  .ag-header-select-all {
    .ag-checkbox-input-wrapper.ag-checked::after {
      color: var(--color-white);
      background-color: var(--color-grey-30);
    }

    .ag-checkbox-input-wrapper.ag-indeterminate::after {
      content: '\f068';
      color: var(--color-white);
      background-color: var(--color-grey-30);
    }
  }

  &.grid-view {
    margin-top: 20px;

    .ag-body-viewport {
      overflow-y: visible;
      height: 100%;

      .ag-full-width-container {
        display: grid;
        max-width: 2132px; // 3x column max-width + 2x gap
        grid-template-columns: repeat(3, minmax(400px, 700px));
        grid-auto-rows: minmax(min-content, max-content);
        overflow-x: auto;
        overflow-y: visible;
        gap: 16px;
        height: 100% !important;
        justify-content: center;

        .ag-row {
          height: unset !important;
          max-height: unset !important;
          min-width: 400px !important;
          width: unset !important;
          position: relative !important;
          border: 1px solid transparent;

          &.ag-row-hover {
            border: 1px solid var(--color-baseline-300);
            border-radius: 8px;
          }
        }
      }

      @media (max-width: 1670px) {
        .ag-full-width-container {
          grid-template-columns: repeat(2, minmax(400px, 1fr));
        }
      }
    }
    
    &.financier .ag-body-viewport .ag-full-width-container {
      max-width: 2116px; // 3x column max-width + 2x gap
      gap: 8px;
    }
  }
}


oes-grid-status-cell {
  width: 100%;
}

.ag-grid-header-sticky {
  .ag-theme-alpine {
    .ag-root-wrapper-body, .ag-root {
      display: unset;
    }

    .ag-side-bar, .ag-side-buttons {
      position: unset;
    }

    .ag-header {
      position: sticky;
      top: 0px;
      z-index: 5;
    }

    .ag-overlay {
      min-height: 200px;
      // explicit width needed because parent is display:unset
      // 100vw - 9vw padding - 81px side nav
      width: calc(91vw - 81px);
    }
  }

  &.top-offset .ag-header {
    top: 235px;
  }

  &.top-offset-large .ag-header {
    top: 275px;
  }
}
