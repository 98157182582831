@mixin theme-nea {
  --color-brand-a-100: var(--color-brand-a-600-opacity-10);
  --color-brand-a-200: var(--color-brand-a-600-opacity-15);
  --color-brand-a-600: #2263AF;
  --color-brand-a-600-opacity-10: rgba(34, 99, 175, 0.1);
  --color-brand-a-600-opacity-15: rgba(34, 99, 175, 0.15);
  --color-brand-a-700: #1c5ca6;
  --color-brand-a-800: #15559f;
  --color-brand-a-900: #0e5199;
  // old brand names will be replaced with brand-a names
  --color-brand: var(--color-brand-a-600);
  --color-brand-opacity-10: var(--color-brand-a-600-opacity-10);
  --color-brand-opacity-15: var(--color-brand-a-600-opacity-15);
  --color-brand-45: var(--color-brand-a-700);
  --color-brand-40: var(--color-brand-a-800);
  --color-brand-25: var(--color-brand-a-900);  
  --color-brand-20: #0e4d90;

  --color-brand-b-600: #DC4928;
  --color-brand-b-700: #D44120;
  // old brand-secondary names will be replaced with brand-b names
  --color-brand-secondary: var(--color-brand-b-600);
  --color-brand-secondary-40: var(--color-brand-b-700);

  --color-grey-96: #FBFDFD;
  --color-grey-93: #F8FAFA;
  --color-grey-90: #F6F8F8;
  --color-grey-85: #F3F5F5;
  --color-grey-80: #EEF0F0;
  --color-grey-80-opacity-40: rgba(238, 240, 240, 0.4);
  --color-grey-70: #E6E8E8;
  --color-grey-60: #DDDFDF;
  --color-grey-50: #C9CBCB;
  --color-grey-50-opacity-20: rgba(201, 203, 203, 0.2);
  --color-grey-45: #C5C7C7;
  --color-grey-40: #B0B2B2;
  --color-grey-35: #979999;
  --color-grey-30: #8E9090;
  --color-grey-25: #666868;
  --color-grey-20: #373939;
  --color-grey-20-opacity-40: rgba(55, 57, 57, 0.4);
  --color-grey-20-opacity-60: rgba(55, 57, 57, 0.6);
  --color-grey-15: #333535;
  --color-grey-10: #2B2D2D;
  --color-grey-10-opacity-50: rgba(43, 45, 45, 0.5);
  --color-grey-8: #313333;
  --color-grey-6: #282A2A;
  --color-grey-4: #232525;

  // Old color names (deprecated)
  --color-brand-darken-4: var(--color-brand-45);
  --color-brand-darken-5: var(--color-brand-40);
  --color-brand-darken-10: var(--color-brand-25);
  --color-brand-darken-12: var(--color-brand-20);

  --color-brand-secondary-darken-5: var(--color-brand-secondary-40);

  --color-light-grey-lighten-5: var(--color-grey-96);
  --color-light-grey-lighten-4: var(--color-grey-93);
  --color-light-grey-lighten-3: var(--color-grey-90);
  --color-light-grey-lighten-2: var(--color-grey-85);
  --color-light-grey: var(--color-grey-80);
  --color-light-grey-opacity-40: var(--color-grey-80-opacity-40);
  --color-2-light-grey: var(--color-grey-70);
  --color-0-med-grey: var(--color-grey-60);
  --color-med-grey: var(--color-grey-50);
  --color-med-grey-opacity-20: var(--color-grey-50-opacity-20);
  --color-2-med-grey: var(--color-grey-45);
  --color-dash-border-grey: var(--color-grey-35);
  --color-drop-down-grey: var(--color-grey-30);
  --color-0-dark-grey: var(--color-grey-25);
  --color-dark-grey: var(--color-grey-20);
  --color-dark-grey-opacity-40: var(--color-grey-20-opacity-40);
  --color-dark-grey-opacity-60: var(--color-grey-20-opacity-60);
  --color-black-grey: var(--color-grey-10);
  --color-black-grey-opacity-50: var(--color-grey-10-opacity-50);
}

@mixin nea-login {
  .map-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-2-med-grey);
  }

  .login-form {
    width: 350px;
    padding: 20px;
    background-color: var(--color-white);
    color: var(--color-black);
    box-shadow: 0 0 18px 0 var(--color-black-opacity-62);

    label {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
      line-height: 1;
    }

    input[type="text"],
    input[type="password"] {
      font-size: .75rem;
    }
  }

  .login-logo {
    margin: 0 auto;
    background-image: url("/assets/images/nea/nea-logo.png");
    background-size: 233px 236px;
    background-repeat: no-repeat;
    height: 236px;
    width: 233px;
  }

  .powered-by-img {
    margin-bottom: 14px;
  }

  .login-input-container {
    margin-top: 14px !important;
  }
}

@mixin nea-eula {
  .container-fluid {
    background-color: var(--color-2-med-grey);
  }

  .login-form {
    background-color: var(--color-white);
  }
}

@mixin nea-eula-bg {
  .eula-bg {
    background-color: var(--color-brand);
  }
}

@mixin nea-side-nav {
  .logo-container a {
    height: 90px;

    .navbar-logo-full {
      background-image: url("/assets/images/nea/nea-logo.png");
    }

    .navbar-logo-small {
      background-image: url("/assets/images/nea/nea-logo.png");
    }
  }

  .switchable-nav {
    height: calc(100vh - 448px) !important;
  }
}

@mixin nea-main-nav {
  .navbar-logo-full {
    width: 89px;
    height: 29px;
    display: block;
    background-size: 89px 29px;
    background-repeat: no-repeat;
    background-image: url("/assets/images/shared/powered-by-odyssey.svg");
  }

  .navbar-brand {
    display: flex;
    align-items: center;
  }

  .navbar-brand:before {
    display: block;
    height: 53px;
    width: 53px;
    background-image: url("/assets/images/nea/nea-logo.png");
    background-size: 53px 53px;
    background-repeat: no-repeat;
    content: '';
    float: left;
    margin-right: 45px;
  }

  .main-menu ul {
    a.nav-link {
      color: var(--color-black-grey);

      &:hover {
        color: var(--color-brand);
      }

      &.bold {
        color: var(--color-brand);

        &:hover {
          color: var(--color-brand);
        }
      }
    }
  }

  .nav-link .bold:after{
    content: ' ';
    display: block;
    border-bottom: 3px solid var(--color-brand);
    padding-bottom: 5px;
  }

  .navbar .dropdown-toggle {
    color: var(--color-brand);
  }

  .main-header {
    background-color: var(--color-white) !important;
  }

  .collapse-menu {
    background-color: var(--color-white);

    .collapse-menu-item {
      color: var(--color-drop-down-grey);
      &::after {
        border-bottom: unset;
        padding: 0;
      }
      &.bold {
        background-color: var(--color-light-grey);
      }
    }
    a.collapse-menu-item {
      text-decoration: none;
    }

    .collapse-menu-item:hover {
      background-color: var(--color-light-grey-lighten-5);
      color: var(--color-dark-grey);
    }

    .nav-help-dropdown:hover {
      background-color: var(--color-light-grey);
    }

    .collapse-menu-item-border {
      border-bottom: 1px solid var(--color-light-grey);
    }
  }

  .animated-icon3 span {
    background: var(--color-brand);
  }

  .profile-collapse-menu {
    .profile-collapse-menu-item {
      color: var(--color-dash-border-grey);
      background-color: var(--color-light-grey-lighten-2);
    }

    .profile-collapse-menu-item:hover {
      color: var(--color-drop-down-grey);
      background-color: var(--color-light-grey);
    }
  }
}

@mixin nea-project-list {
  .project-header {
    background-color: var(--color-dark-grey);
    color: var(--color-white);
  }
  .project-list-header {
    a.back {
      color: var(--color-white) !important;
      &:hover {
        color: var(--color-light-grey) !important;
      }
    }
  }
}

@mixin nea-bulk-upload-icons {
  .project-group-action-body {
    svg {
      rect {
        fill: var(--color-brand);
      }

      polygon {
        fill: var(--color-brand);
      }

      path {
        fill: var(--color-brand);
      }
    }
  }
}

@mixin nea-project-header {
  .update-fin-model-button {
    background-color: var(--color-white);
    color: var(--color-brand);
    border-color: var(--color-white);

    &:hover, &:active {
      background-color: var(--color-white) !important;
      border-color: var(--color-white) !important;
    }

    &:disabled {
      color: var(--color-white) !important;
      background-color: var(--color-med-grey) !important;
      border-color: var(--color-med-grey) !important;
    }
  }
}
