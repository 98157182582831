@page {
}

@media print {
  .oes-page-break {
    page-break-before: always;
    page-break-inside: avoid;
  }

  .fm-navigation nav,
  .fc-widget-normal, // freshchat
  .theme-box,
  .side-nav,
  .theme-box,
  .actions {
    display: none !important;
  }

  .main-header {
    height: 60px !important;
    // when you use fixed, you can display the footer in each page but it overlaps a content.
    position: static !important;
    oes-user-setting,
    oes-side-navigation,
    .navbar-toggler,
    .navbar-collapse,
    oes-role-selector {
      display: none !important;
    }
  }

  .nav-main-container {
    padding-top: 0px !important;
    .project-header {
      display: none;
      oes-project-financial-summary {
        display: none;
      }
    }
  }

  oes-side-navigation-secondary,
  oes-side-navigation {
    display: none !important;
  }
  .side-nav-secondary {
    display: none;
  }
  .nav-body .nav-main-container {
    margin-top: 0 !important;
    width: 100vw;
  }
  .program-header {
    border-bottom: none !important;
  }

  oes-program-detail {
    .tabset {
      display: none;
    }
    oes-breadcrumb {
      display: none;
    }
  }

  footer {
    // when you use fixed, you can display the footer in each page but it overlaps a content.
    position: static;
    bottom: 0;
    p:first-child {
      margin: 0;
    }
    p:last-child {
      display: none;
    }
  }

  oes-fm-navigation .project-list-header,
  oes-finance .project-list-header {
    max-height: 50px !important;
    position: relative !important;
    background-color: transparent !important;
    color: var(--color-black) !important;

    .back {
      display: none !important;
    }
  }

  input:disabled {
    background-color: var(--color-white) !important;
  }

  .wrapper {
    height: 100% !important;

    .read-only-text {
      background-color: var(--color-white) !important;
    }
  }

  .portfolio-page-container {
    padding: 0px !important;
  }
  .project-group-main-content {
    width: 100% !important;
  }

  // force to display all tab contents
  .tab-pane {
    display: block;
  }

  a {
    text-decoration: none !important;
  }
}
