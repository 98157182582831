$max-columns: 10;
$increments: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

@for $col from 1 through $max-columns {
  @each $width in $increments {
    .column-#{$col}-width-#{$width} {
      table td:nth-child(#{$col}) {
        width: $width * 1%;
      }
    }
  }
}
