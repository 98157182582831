@mixin theme-odyssey {
  --color-brand-a-900: #C26000;
  --color-brand-a-800: #E67200;
  --color-brand-a-700: #EE7802;
  --color-brand-a-600: #FF7F00;
  --color-brand-a-600-opacity-15: var(--color-brand-a-200);
  --color-brand-a-600-opacity-10: var(--color-brand-a-100);
  --color-brand-a-200: #FFD7AF;
  --color-brand-a-100: #FFE7CF;

  // Old color names (deprecated)
  --color-brand: #FF7F00;
  --color-brand-opacity-10: rgba(255, 127, 0, 0.1);
  --color-brand-opacity-15: rgba(255, 127, 0, 0.15);
  --color-brand-45: #EB7500;
  --color-brand-40: var(--color-brand-a-800);
  --color-brand-25: #cf6430;
  --color-brand-20: #b35426;
  // These old colors will be mapped to new ones:
  // --color-brand: var(--color-brand-a-600);
  // --color-brand-opacity-10: var(--color-brand-a-100);
  // --color-brand-opacity-15: var(--color-brand-a-200);
  // --color-brand-45: var(--color-brand-a-700);
  // --color-brand-40: var(--color-brand-a-800);
  // --color-brand-25: var(--color-brand-a-900);

  --color-grey-96: #FBFDFD;
  --color-grey-93: #F8FAFA;
  --color-grey-90: #F6F8F8;
  --color-grey-85: #F3F5F5;
  --color-grey-80: #EEF0F0;
  --color-grey-80-opacity-40: rgba(84, 221, 221, 0.4);
  --color-grey-70: #E6E8E8;
  --color-grey-60: #DDDFDF;
  --color-grey-50: #C9CBCB;
  --color-grey-50-opacity-20: rgba(201, 203, 203, 0.2);
  --color-grey-45: #C5C7C7;
  --color-grey-40: #B0B2B2;
  --color-grey-35: #979999;
  --color-grey-30: #8E9090;
  --color-grey-25: #666868;
  --color-grey-20: #373939;
  --color-grey-20-opacity-40: rgba(55, 57, 57, 0.4);
  --color-grey-20-opacity-60: rgba(55, 57, 57, 0.6);
  --color-grey-15: #333535;
  --color-grey-10: #2B2D2D;
  --color-grey-10-opacity-50: rgba(43, 45, 45, 0.5);
  --color-grey-8: #313333;
  --color-grey-6: #282A2A;
  --color-grey-4: #232525;
  // The grays above matched to the baseline palette closest gray
  // --color-grey-96: var(--color-baseline-25);
  // --color-grey-93: var(--color-baseline-50);
  // --color-grey-90: var(--color-baseline-50);
  // --color-grey-85: var(--color-baseline-100);
  // --color-grey-80: var(--color-baseline-100);
  // --color-grey-80-opacity-40: rgba(242, 244, 247, 0.4);
  // --color-grey-70: var(--color-baseline-200);
  // --color-grey-60: var(--color-baseline-200);
  // --color-grey-50: var(--color-baseline-300);
  // --color-grey-50-opacity-20: rgba(208, 213, 221, 0.2);
  // --color-grey-45: var(--color-baseline-300);
  // --color-grey-40: var(--color-baseline-400);
  // --color-grey-35: var(--color-baseline-400);
  // --color-grey-30: var(--color-baseline-500);
  // --color-grey-25: var(--color-baseline-600);
  // --color-grey-20: var(--color-baseline-700);
  // --color-grey-20-opacity-40: rgba(52, 64, 84, 0.4);
  // --color-grey-20-opacity-60: rgba(52, 64, 84, 0.6);
  // --color-grey-15: var(--color-baseline-700);
  // --color-grey-10: var(--color-baseline-800);
  // --color-grey-10-opacity-50: rgba(29, 41, 57, 0.5);
  // --color-grey-8: var(--color-baseline-800);
  // --color-grey-6: var(--color-baseline-900);
  // --color-grey-4: var(--color-baseline-900);

  // Even older color names (deprecated 2)
  --color-brand-darken-4: var(--color-brand-45);
  --color-brand-darken-5: var(--color-brand-40);
  --color-brand-darken-10: var(--color-brand-25);
  --color-brand-darken-12: var(--color-brand-20);

  --color-light-grey-lighten-5: var(--color-grey-96);
  --color-light-grey-lighten-4: var(--color-grey-93);
  --color-light-grey-lighten-3: var(--color-grey-90);
  --color-light-grey-lighten-2: var(--color-grey-85);
  --color-light-grey: var(--color-grey-80);
  --color-light-grey-opacity-40: var(--color-grey-80-opacity-40);
  --color-2-light-grey: var(--color-grey-70);
  --color-0-med-grey: var(--color-grey-60);
  --color-med-grey: var(--color-grey-50);
  --color-med-grey-opacity-20: var(--color-grey-50-opacity-20);
  --color-2-med-grey: var(--color-grey-45);
  --color-dash-border-grey: var(--color-grey-35);
  --color-drop-down-grey: var(--color-grey-30);
  --color-0-dark-grey: var(--color-grey-25);
  --color-dark-grey: var(--color-grey-20);
  --color-dark-grey-opacity-40: var(--color-grey-20-opacity-40);
  --color-dark-grey-opacity-60: var(--color-grey-20-opacity-60);
  --color-black-grey: var(--color-grey-10);
  --color-black-grey-opacity-50: var(--color-grey-10-opacity-50);
}

@mixin odyssey-login {
  .map-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/shared/mm_grid.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: var(--color-white);
  }

  .map-bg-front {
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    opacity: .2;
  }

  .login-form {
    background-color: rgba(255, 255, 255, 0.92);
  }

  .login-logo {
    background-image: url("/assets/images/logo-odyssey-login-dark.png");
    background-size: 248px 80px;
    width: 248px;
    height: 80px;
    margin: 33px auto 0;
    background-repeat: no-repeat;
  }

  .powered-by-img {
    display: none;
  }
}

@mixin odyssey-eula {
  .container-fluid {
    background-color: var(--color-black);
  }

  .login-form {
    background-color: var(--color-light-grey-opacity-40);
  }
}

@mixin odyssey-eula-bg {
  .eula-bg {
    background-color: var(--color-dark-grey);
  }
}

@mixin odyssey-main-nav {
  .navbar-logo-full {
    width: 124px;
    height: 40px;
    display: block;
    background-size: 124px 40px;
    background-repeat: no-repeat;
    background-image: url('/assets/images/logo-odyssey.png');
  }

  .nav-link .bold:after{
    content: ' ';
    display: block;
    border-bottom: 3px solid var(--color-white);
    padding-bottom: 5px;
  }

  .navbar .dropdown-toggle {
    color: var(--color-white);
  }

  .collapse-menu {
    background-color: var(--color-black-grey);

    .collapse-menu-item {
      color: var(--color-med-grey);
      &::after {
        border-bottom: unset;
        padding: 0;
      }
      &.bold {
        background-color: var(--color-dark-grey);
      }
    }
    a.collapse-menu-item {
      text-decoration: none;
    }

    .collapse-menu-item:hover {
      background-color: var(--color-black-grey-darken-1);
      color: var(--color-light-grey);
    }

    .nav-help-dropdown:hover {
      background-color: var(--color-grey-15);
    }

    .collapse-menu-item-border {
      border-bottom: 1px solid var(--color-dark-grey);
    }
  }

  .animated-icon3 span {
    background: var(--color-med-grey);
  }

  .profile-collapse-menu {
    .profile-collapse-menu-item {
      color: var(--color-white-opacity-40);
      background-color: var(--color-dark-grey);
    }

    .profile-collapse-menu-item:hover {
      color: var(--color-white-opacity-60);
      background-color: var(--color-grey-15);
    }
  }
}

@mixin odyssey-project-list {
  oes-project-financial-summary,
  .project-list-header {
    background-color: var(--color-dark-grey);
    color: var(--color-white);
  }
}

@mixin odyssey-side-nav {
  .logo-container a {
    height: 50px;

    .navbar-logo-full {
      background-image: url('/assets/images/odyssey/logo-odyssey.png');
    }

    .navbar-logo-small {
      background-image: url('/assets/images/odyssey/logo-odyssey-simple.png');
    }
  }

  .switchable-nav {
    height: calc(100vh - 366px) !important;
  }

  .side-nav {
    background-attachment: fixed;
    position: relative;
    z-index: 11000;
    background: var(--color-brand-darken-4);

    .side-nav-group {
      background: var(--color-brand);
      border-bottom: 2px solid var(--color-brand-darken-4);
    }

    .top-side-nav-group {
      border-top: 2px solid var(--color-brand-darken-4);
    }

    ul {
      li {
        a {
          color: var(--color-white);

          &.selected {
            background: var(--color-white-opacity-20);

            &:before {
              background: var(--color-white);
            }
          }
        }
      }
    }
  }
}

@mixin odyssey-side-nav-icons {
  .nav-svg-icn {
    margin-right: 10px;
    height: 17px;

    .stroke {
      stroke: var(--color-white);

      .selected & {
        stroke: var(--color-white);
      }
    }
  }

  .icn-site-specs {
    g {
      fill: var(--color-white);
    }
  }

  .icn-load {
    .a {
      fill: var(--color-white);
    }

    .a-stroke {
      stroke: var(--color-white);
    }
  }

  .icn-design {
    g {
      fill: var(--color-white);
    }
  }

  .icn-distribution {
    g {
      stroke: var(--color-white);
    }
  }

  .icn-revenues {
    .a {
      fill: var(--color-white);
    }

    .a-stroke {
      stroke: var(--color-white);
    }
  }

  .icn-costs {
    .a {
      fill: var(--color-white);
    }
  }

  .icn-financials {
    g {
      fill: var(--color-white);
    }
  }

  .icn-prospectus {
    g {
      fill: var(--color-white);
    }
  }
}
