%plus-minus {
  font-size: 26px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.minusSign {
  @extend %plus-minus;

  &:before {
    content: url('/assets/images/shared/simple-file-manager/icn-delete.svg');
  }
}

.plusSign {
  @extend %plus-minus;

  &:before {
    content: url('/assets/images/shared/simple-file-manager/icn-add.svg');
  }
}

.featured-icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--color-brand-a-100);
  color: var(--color-brand-a-700);
  display: flex;
  align-items: center;
  justify-content: center;
  &.color-baseline {
    background-color: var(--color-baseline-100);
    color: var(--color-baseline-700);
  }
  &.color-brand-b {
    background-color: var(--color-brand-b-100);
    color: var(--color-brand-b-700);
  }
  &.color-accent-a {
    background-color: var(--color-accent-a-100);
    color: var(--color-accent-a-700);
  }
  &.color-accent-b {
    background-color: var(--color-accent-b-100);
    color: var(--color-accent-b-700);
  }
  &.color-accent-c {
    background-color: var(--color-accent-c-100);
    color: var(--color-accent-c-700);
  }
}


