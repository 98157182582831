.data-box {
  background: var(--color-white);
  padding: 20px 30px;
  width: 100%;
  text-align: center;
}

.data-box--empty {
  border: 1px dashed var(--color-dash-border-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100%;
  min-height: 170px;

  p {
    margin-bottom: 0;

    + .btn {
      margin-top: 1rem;
    }
  }

  &--large {
    height: 370px;

    p {
      font-size: 14px;

      + .btn {
        margin-top: 40px;
      }
    }
  }
}

.data-box--empty-info {
  max-width: 320px;
}

.data-box--empty-icon {
  height: 40px;
  margin-bottom: 20px;

  + h4 {
    margin-top: 20px;
  }

  &--large {
    height: 80px;
    margin-bottom: 40px;
  }
}
