.col-form-label--sm {
  margin-top: 0;
  padding-top: calc(0.4rem - 1px * 2);
  padding-bottom: calc(0.4rem - 1px * 2);
}

form {
  .form-control,
  select,
  input {
    border-color: var(--color-med-grey);
    color: var(--color-black-grey);
    font-size: 14px !important;

    &::placeholder {
      color: var(--color-med-grey);
    }

    &.select-w-placeholder {
      color: var(--color-med-grey);
    }

    &.no-border {
      border: none;
    }

    &:focus {
      border-color: var(--color-black-grey);
      &.ng-invalid {
        border-color: var(--color-red) !important;
      }
    }

    &.ng-invalid {
      border-color: var(--color-red) !important;
    }
    &:disabled {
      background-color: var(--color-light-grey);
      cursor: not-allowed;
    }
  }
}

.has-success .form-control:not(:focus) {
  border-color: var(--color-med-grey) !important;
}

.form-control--sm {
  padding: .45rem .5rem .4rem;
  font-size: 14px;
  line-height: 1;
  height: 30px !important;
}

.form-text {
  font-size: .625rem;
}

.input-group--sm {
  .form-control {
    height: 30px;
  }

  .input-group-addon {
    height: 30px;
    border-radius: 0;
    font-size: 14px;
    padding: 0.5rem 0.5rem;
  }
}

// for new generation design item
.input-group {
  input:not([type=checkbox]) {
    width: 100%;
  }
  .input-group-addon {
    line-height: 30px;
    min-width: 42px;
    padding: 0 5px;
    text-align: center;
    &.suffix {
      border-left: unset;
      min-width: 48px;
    }
    &.prefix {
      min-width: 30px;;
    }
  }
}

button:disabled {
  background-color: var(--color-med-grey);
  border-color: var(--color-med-grey);
}

.uploaded-document {
  color: var(--color-orange);
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  .btn:hover {
    cursor: pointer;
  }
}

// TODO: this is not good...
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

input {
  margin: 0;
  height: 40px;
  padding: 0 10px;
  font-size: 14px !important;
  border: 1px solid var(--color-med-grey);
  border-radius: 8px;
  &:focus {
    border: 1px solid var(--color-black-grey);
    outline: unset;
  }
  &:read-only {
    border: 1px solid var(--color-med-grey);
    outline: unset;
    background-color: var(--color-light-grey);
    &:focus {
      border: 1px solid var(--color-med-grey);
      outline: unset;
      background-color: var(--color-light-grey);
    }
  }
  background-clip: unset;
}

input[type="date"].form-control--sm,
input[type="time"].form-control--sm,
input[type="datetime-local"].form-control--sm,
input[type="month"].form-control--sm {
  height: 30px;

  &::placeholder {
    color: var(--color-med-grey);
  }
}

.form-error {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-red);
  display: block;
  width: inherit;
}

.no-form-error {
  display: block;
  height: 16px;
}

select {
  height: 40px !important;
  border-radius: 8px;
  border-color: var(--color-med-grey);
  padding: 0 10px;
  &:focus {
    border: solid 1px var(--color-dark-grey);
    outline: unset;
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
  option {
    .default-option {
      color: var(--color-med-grey);
    }
    &:focus {
      border-color: var(--color-black-grey);
      outline: unset;
    }
  }
}
