.text-red {
  color: var(--color-red);
}

.text-black {
  color: var(--color-black);
}

.text-dark-grey {
  color: var(--color-dark-grey);
}

.text-black-grey {
  color: var(--color-black-grey);

  &:hover {
    color: var(--color-black-grey);
  }
}

.color-font-primary {
  color: var(--color-font-primary);
}

.color-font-secondary {
  color: var(--color-font-secondary);
}

.color-font-tertiary {
  color: var(--color-font-tertiary);
}

.color-error {
  color: var(--color-error-700);
}

.color-brand {
  color: var(--color-brand-a-700);
}

.text-link--color-brand {
  color: var(--color-brand) !important;
  text-decoration: underline;
  cursor: pointer;
}

h1, .text-4xl {
  font-size: 36px;
  line-height: 44px;
}

h2, .text-3xl {
  font-size: 30px;
  line-height: 38px;
}

h3, .text-2xl {
  font-size: 24px;
  line-height: 32px;

  // Deprecated
  small {
    font-size: 12px;
  }
}

h4, .text-xl {
  font-size: 20px;
  line-height: 1.5;
}

// For backwards compatibility, leaving h5 as 14px
h5 {
  // In order to prevent unwanted changes to older styles
  // temporarily keeping the font-size as the old 14px value
  font-size: 14px;
  // These will be the new values when we're ready to use them
  // font-size: 18px;
  // line-height: 28px;
}

// The actual new h5 styles
.text-lg, .h5 {
  font-size: 18px;
  line-height: 28px;
}

// Depracated
h6 {
  font-size: 11px;
  line-height: 1.5;
  font-weight: 600;
}

.weight-thin {
  font-weight: 100;
}

.weight-extralight {
  font-weight: 200;
}

.weight-light {
  font-weight: 300;
}

.weight-regular {
  font-weight: 400;
}

.weight-medium {
  font-weight: 500;
}

.weight-semibold {
  font-weight: 600;
}

// '.bold' deprecated
.weight-bold, .bold {
  font-weight: 700;
}

.weight-extrabold {
  font-weight: 800;
}

.weight-heavy {
  font-weight: 900;
}

a {
  cursor: pointer;
}

// Class selector '.p' deprecated
p, .p {
  font-size: 14px;
  line-height: 20px;

  // Deprecated
  &.light {
    color: var(--color-grey-30);
  }

  small {
    font-size: 10px;
  }
}

.text-md {
  font-size: 16px;
  line-height: 1.5;
}

.text-sm {
  font-size: 14px;
  line-height: 20px;
}

.text-xs {
  font-size: 12px;
  line-height: 1.5;
}

label {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;

  &.required::after {
    content: ' *';
    color: var(--color-red);
    font-size: 12px;
    line-height: 1.5px;
    font-weight: bold;
  }
}
