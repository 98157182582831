.tab-collapse-list {
  > li {
    position: relative;
    list-style-type: none;
    padding: 20px 10px 20px 20px;
    border-left: solid 4px var(--color-light-grey);

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 90%;
      left: 5%;
      height: 1px;
      background-color: var(--color-med-grey);
    }

    &:hover {
      cursor: pointer;
      border-left: solid 4px var(--color-brand);
    }

    h4 {
      .btn-group {
        display: block;
        float: right;
        color: var(--color-orange);
        margin-right: 0;
      }
    }

    p {
      margin: 0;
    }

    &.selected {
      border-left: solid 4px var(--color-brand);
      background-color: var(--color-white);

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        width: 90%;
        left: 5%;
        height: 1px;
        background: var(--color-light-grey);
      }

      &:after {
        height: 0;
      }
    }

    .subtext {
      color: var(--color-drop-down-grey);
    }
  }
}
