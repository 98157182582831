// to override arch wizard globally
@import '../../../../node_modules/@nubebytes/angular-archwizard/styles/archwizard.scss';

aw-wizard {
  background-color: var(--color-white);
  border: solid 1px var(--color-med-grey);

  aw-wizard-navigation-bar.horizontal {
    height: auto !important;
    padding: 20px;

    &.small {
      ul.steps-indicator {
        li {
          a {
            line-height: 18px !important;
            text-decoration: unset !important;
            .label {
              text-transform: unset !important;
              font-weight: normal;
              line-height: 20px;
            }
            &:hover {
              .label {
                color: var(--color-black) !important;
              }
            }
          }
          &.current {
            .label {
              color: var(--color-brand) !important;
            }
            .step-indicator {
              background-color: var(--color-brand) !important;
            }
          }
        }
      }
    }

    &.large-filled-symbols {
      ul.steps-indicator {
        padding: 10px !important;
        li {
          a {
            min-height: 18px !important;
            color: var(--color-white) !important;
            font-weight: bold !important;
            .label {
              text-transform: unset !important;
              font-weight: normal;
              line-height: 20px;
            }
            &:hover {
              .label {
                color: var(--color-black) !important;
              }
            }
          }
          &.current {
            .label {
              color: var(--color-brand) !important;
            }
            .step-indicator {
              background-color: var(--color-brand) !important;
            }
          }
          .step-indicator {
            left: -16px !important;
            width: 20px !important;
            height: 20px !important;
            line-height: 20px !important;
            font-size: 12px !important;
            color: var(--color-white) !important;
          }
        }
      }
    }
  }

  aw-wizard-navigation-bar.vertical {
    border-right: solid 1px var(--color-med-grey);
    height: auto !important;
    padding: 20px;

    &.small {
      ul.steps-indicator {
        li {
          a {
            line-height: 18px !important;
            text-decoration: unset !important;
            .label {
              text-transform: unset !important;
              font-weight: normal;
              line-height: 20px;
            }
            &:hover {
              .label {
                color: var(--color-black) !important;
              }
            }
          }
          &.current {
            .label {
              color: var(--color-brand) !important;
            }
            .step-indicator {
              background-color: var(--color-brand) !important;
            }
          }
        }
      }
    }

    &.large-filled-symbols {
      ul.steps-indicator {
        padding: 10px !important;
        li {
          a {
            min-height: 18px !important;
            color: var(--color-white) !important;
            font-weight: bold !important;
            .label {
              text-transform: unset !important;
              font-weight: normal;
              line-height: 20px;
            }
            &:hover {
              .label {
                color: var(--color-black) !important;
              }
            }
          }
          &.current {
            .label {
              color: var(--color-brand) !important;
            }
            .step-indicator {
              background-color: var(--color-brand) !important;
            }
          }
          .step-indicator {
            left: -16px !important;
            width: 20px !important;
            height: 20px !important;
            line-height: 20px !important;
            font-size: 12px !important;
            color: var(--color-white) !important;
          }
        }
      }
    }
  }

  .wizard-steps {
    padding: 20px;
  }
}
