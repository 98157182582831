// oes-home {
//   // height: calc(100% - 56px);
//   // display: flex;
//   flex: 1;
// }

// oes-investor-profiles,
// oes-investor-profile,
// oes-vendor-profiles,
// oes-vendor-profile,
// oes-my-quotes {
//   @media(min-height: 600px) and (min-width: 1024px) {
//     > .container-fluid {
//       padding-top: 120px !important;
//     }
//   }
// }

.container-fluid {
  padding: 0 60px;
}
