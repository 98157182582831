ul.oes-tab {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: solid 1px var(--color-med-grey);
  li.tab-item {
    margin: 0 20px 0 0;
    a {
      text-decoration: none;
      font-size: 14px;
      display: block;
      &.active {
        color: var(--color-brand);
        font-weight: 600;
        line-height: 21px;
        padding: 0 0 5px 0;
        position: relative;
        .tab-text:after {
          content: '';
          position: absolute;
          bottom: -2px;
          width: 100%;
          height: 4px;
          display: block;
          background-color: var(--color-brand);
          left: 0;
        }
      }
    }
  }
}
