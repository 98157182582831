ngx-bootstrap-multiselect {
  .dropdown-inline {
    display: block;
    width: 100%;
  }

  .input-group {
    &.search-container {
      padding: 0 9px 5px 5px;

      .input-group-prepend {
        line-height: 2.666;
        padding: 0 5px 0 3px;
      }
    }
  }

  button {
    &.dropdown-select {
      border: solid 1px var(--color-med-grey);
      background-color: var(--color-white);
      border-radius: 0;
      color: var(--color-dark-grey);
      display: block;
      font-size: 12px;
      line-height: 30px;
      padding: 0 10px;
      height: 30px;
      width: 100%;

      &::after {
        color: var(--color-dark-grey);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .dropdown-item {
    color: var(--color-black-grey);

    a {
      color: var(--color-black);
      text-decoration: none;

      &:hover {
        color: var(--color-black);
        text-decoration: unset;
      }
    }

    &.active {
      background-color: var(--color-white);
      color: var(--color-black-grey);
    }

    &:active {
      color: var(--color-black-grey);
    }

    &:hover {
      background-color: var(--color-light-grey);
    }
  }

  .dropdown-item.empty {
    width: 420px;
    max-width: 420px;
    white-space: normal;
  }
}
