// To apply all <button></button>
button {
  font-family: Inter;
  white-space: nowrap;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    cursor: pointer;
  }
}

// sometime make a button without <button></button>
.btn {
  font-family: Inter;
  font-size: 16px;
  height: 44px;
  line-height: unset;
  display: flex;
  align-items: center;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.btn-text-only {
  background: transparent;
  border-radius: 6px;
  border: none;
  color: var(--color-brand);
  padding-left: 0;
  padding-right: 0;

  &.inactive {
    cursor: not-allowed;
    background: transparent;
    border: none;
  }

  &[disabled], &.disabled {
    cursor: not-allowed;
    opacity: .5;
    background: transparent;
    border: none;
  }
}

.btn-fresh {
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  box-sizing: border-box;
  img, svg {
    margin-right: 8px;
  }
  &:not(:first-child) {
    margin-left: 8px;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn-primary {
  border: 1px solid var(--color-brand);
  background-color: var(--color-brand);
  border-radius: 8px;
  text-align: center;
  color: var(--color-white);
  &.inactive {
    background-color: var(--color-med-grey);
    border: none;
    cursor: not-allowed;
  }

  &:focus {
    background-color: var(--color-brand-darken-5) !important;
    border-color: var(--color-brand-darken-5) !important;
    box-shadow: none;
    color: var(--color-white);
  }

  &:hover {
    background-color: var(--color-brand-darken-5) !important;
    border-color: var(--color-brand-darken-5) !important;
  }

  &[disabled], &.disabled {
    background-color: var(--color-grey-80) !important;
    border-color: var(--color-grey-80) !important;
    cursor: not-allowed;
    color: var(--color-grey-30);
    font-weight: 500;

    &:hover {
      background-color: var(--color-med-grey) !important;
      border-color: var(--color-med-grey) !important;
    }
  }
}

.btn-primary-light {
  background-color: var(--color-brand-opacity-10);
  border-radius: 8px;
  text-align: center;
  color: var(--color-brand);
  &.inactive {
    background-color: var(--color-med-grey);
    border: none;
    cursor: not-allowed;
  }

  &:focus {
    background-color: var(--color-brand-opacity-15) !important;
    border-color: var(--color-brand-opacity-15) !important;
    box-shadow: none;
    color: var(--color-brand);
  }

  &:hover {
    color: var(--color-brand) !important;
    background-color: var(--color-brand-opacity-15) !important;
  }

  &[disabled], &.disabled {
    background-color: var(--color-grey-80) !important;
    border-color: var(--color-grey-80) !important;
    cursor: not-allowed;
    color: var(--color-grey-30);
    font-weight: 500;

    &:hover {
      background-color: var(--color-med-grey) !important;
      border-color: var(--color-med-grey) !important;
    }
  }
}

.btn-transparent-light {
  border: none;
  background-color: transparent;
  border-radius: 8px;
  text-align: center;
  color: var(--color-grey-25);
  &.inactive {
    cursor: not-allowed;
  }

  &:hover, &.active {
    background-color: var(--color-brand-opacity-10) !important;
    color: var(--color-brand) !important;
  }

  &[disabled], &.disabled {
    cursor: not-allowed;
    color: var(--color-grey-35);
    font-weight: 500;

    &:hover {
      background-color: transparent !important;
      color: var(--color-grey-35) !important;
    }
  }
}

.btn-grey {
  border: 1px solid var(--color-grey-80);
  background-color: var(--color-grey-80);
  border-radius: 8px;
  text-align: center;
  color: var(--color-grey-25);
  &.inactive {
    background-color: var(--color-grey-50);
    border: none;
    cursor: not-allowed;
  }

  &:focus {
    background-color: var(--color-grey-70) !important;
    border-color: var(--color-grey-70) !important;
    box-shadow: none;
    color: var(--color-grey-30);
  }

  &:hover {
    background-color: var(--color-grey-70) !important;
    border-color: var(--color-grey-70) !important;
  }

  &[disabled], &.disabled {
    background-color: var(--color-grey-80) !important;
    border-color: var(--color-grey-80) !important;
    cursor: not-allowed;
    opacity: .4;

    &:hover {
      background-color: var(--color-grey-80) !important;
      border-color: var(--color-grey-80) !important;
    }
  }
}

.btn-blue {
  border: 1px solid var(--color-ocean-blue);
  background-color: var(--color-ocean-blue);
  border-radius: 8px;
  text-align: center;
  color: var(--color-white);
  &.inactive {
    background-color: var(--color-grey-50);
    border: none;
    cursor: not-allowed;
  }

  &:focus {
    background-color: var(--color-dark-ocean-blue) !important;
    border-color: var(--color-dark-ocean-blue) !important;
    box-shadow: none;
    color: var(--color-white);
  }

  &:hover {
    background-color: var(--color-dark-ocean-blue) !important;
    border-color: var(--color-dark-ocean-blue) !important;
    color: var(--color-white);
  }

  &[disabled], &.disabled {
    background-color: var(--color-grey-80) !important;
    border-color: var(--color-grey-80) !important;
    cursor: not-allowed;
    opacity: .4;

    &:hover {
      background-color: var(--color-grey-80) !important;
      border-color: var(--color-grey-80) !important;
    }
  }
}

.btn-secondary {
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-grey-60) !important;
  color: var(--color-black) !important;
  border-radius: 8px;
  text-align: center;
  &.inactive {
    background-color: var(--color-med-grey) !important;
    border: none;
    color: var(--color-white);
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: none;
  }

  &:hover{
    background-color: var(--color-light-grey) !important;
  }

  &[disabled], &.disabled {
    color: var(--color-white) !important;
    background-color: var(--color-med-grey) !important;
    border-color: var(--color-med-grey) !important;
    cursor: not-allowed;

    &:hover {
      background-color: var(--color-med-grey) !important;
      border-color: var(--color-med-grey) !important;
    }
  }
}

.btn-destructive {
  background-color: var(--color-red) !important;
  border: 1px solid var(--color-red) !important;
  border-radius: 8px;
  color: var(--color-white);
  text-align: center;

  &.inactive {
    background-color: var(--color-med-grey);
    border: none;
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: var(--color-red-darken-5) !important;
    border-color: var(--color-red-darken-5) !important;
  }

  &[disabled], &.disabled {
    background-color: var(--color-med-grey) !important;
    border-color: var(--color-med-grey) !important;
    cursor: not-allowed;
  }
}

.btn-small {
  background-color: var(--color-brand);
  border: 1px solid var(--color-brand);
  border-radius: 8px;
  color: var(--color-white);
  font-size: 12px;
  font-weight: normal;
  height: 25px;
  line-height: 25px;
  padding: 0 15px;
  text-align: center;

  &:hover {
    background-color: var(--color-brand-darken-5);
    border-color: var(--color-brand-darken-5);
  }
}

.btn-grid-cell {
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: 8px;
  color: var(--color-black);
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  padding: 0 15px;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }
}

.btn-circle {
  margin: 0 10px 0 0;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   line-height: 40px;
   padding: 0;
   background-color: var(--color-brand);
   display: flex;
   justify-content: center;
   align-items: center;
   &:hover {
     opacity: 0.6;
     cursor: pointer;
   }
   &.disabled {
     background-color: var(--color-0-med-grey);
     &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-square {
  margin: 0 10px 0 0;
  border-radius: 0px;
  width: 128px;
  height: 128px;
  padding: 0;
  background-color: var(--color-white);
  // border: solid 1px var(--color-med-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  // border-top: solid 2px var(--color-brand);
  border-bottom: solid 2px var(--color-brand);
  box-shadow: 0 0px 0px -9px rgba(0, 0, 0, 0.1), 0 1px 13px 0 rgba(0, 0, 0, 0.05), 0 1px 16px 0 rgba(0, 0, 0, 0.12);
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  &.disabled {
    background-color: var(--color-light-grey);
    &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-light {
  font-weight: 100;
  border: 0;
}

.btn-group {
  margin: 10px 10px 10px 0;
}

.ellipses {
  display: block;
  height: 30px;
  width: 30px;

  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 6px;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: var(--color-brand);
    box-shadow: 7px 0 0 0 var(--color-brand), 14px 0 0 0 var(--color-brand);
  }

  &:hover {
    cursor: pointer;
    &:after {
      background: var(--color-brand-darken-5);
      box-shadow: 7px 0 0 0 var(--color-brand-darken-5), 14px 0 0 0 var(--color-brand-darken-5);
    }
  }

  &--white {
    &:hover {
      cursor: default;
    }

    &:after {
      background: var(--color-white);
      box-shadow: 7px 0 0 0 var(--color-white),
      14px 0 0 0 var(--color-white);
    }
  }
}

.btn-lg {
  height: 45px;
  border-radius: 100px;
  font-size: 18px;
}

.btn-med {
  height: 36px;
  border-radius: 8px;
  font-size: 14px;
}

.btn-ex-small {
  height: 22px;
  border-radius: 100px;
  font-size: 12px;
}

// to prevent showing up bootstrap color when a browser off focus
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--color-brand-darken-5) !important;
  border-color: var(--color-brand-darken-5) !important;
}

.back-to-top-button {
  margin: 20px 0 30px;

  img {
    margin-left: 10px;
  }
}
