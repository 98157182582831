// Override bootstrap variables.

// OES style guide
// https://projects.invisionapp.com/share/95UYEX8JXYQ#/screens
$black-grey: #2B2B2B;
$dark-grey: #373837;
$med-grey: #C9C9C9;
$light-grey: #EEEEEE;
$white: #FFFFFF;

$orange: #FF7F00;
$dark-orange: #E67200;
$light-orange: #FFE5CC;
$yellow: #F7B12D;
$light-yellow: #EFE1C7;
$green: #0DBC8C;
$dark-green: #0EA87E;
$light-green: #C1E4DA;
$blue: #30819F;
$light-blue: #C8D8DE;
$red: #CC2A16;
$light-red: #E7C6C2;


$link-decoration:       underline;
// We don't use Roboto-light and Roboto-bold to reduce file loading time.
$font-family-sans-serif: Inter, Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$font-family-serif: Inter, Roboto, Georgia, "Times New Roman", Times, serif;

$font-size-base: 12px;
$font-size-h1: 36px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 18px;
$font-size-h5: 14px;
$font-size-h6: 14px;

$border-radius: 0;

// form
$input-padding-x: 10px;
$input-padding-y: 0px;
$input-border-focus: $black-grey;
