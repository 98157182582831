@mixin theme-avorenewables {
  --color-brand-a-100: var(--color-brand-a-600-opacity-10);
  --color-brand-a-200: var(--color-brand-a-600-opacity-15);
  --color-brand-a-600: #006341;
  --color-brand-a-600-opacity-10: rgba(0, 99, 65, 0.1);
  --color-brand-a-600-opacity-15: rgba(0, 99, 65, 0.15);
  --color-brand-a-700: #005A3B;
  --color-brand-a-800: #005236;
  --color-brand-a-900: #004A31;
  // old brand names will be replaced with brand-a names
  --color-brand: var(--color-brand-a-600);
  --color-brand-opacity-10: var(--color-brand-a-600-opacity-10);
  --color-brand-opacity-15: var(--color-brand-a-600-opacity-15);
  --color-brand-45: var(--color-brand-a-700);
  --color-brand-40: var(--color-brand-a-800);
  --color-brand-25: var(--color-brand-a-900);  
  --color-brand-20: #00432C;

  --color-grey-96: #FCF6EE;
  --color-grey-93: #F9F3EB;
  --color-grey-90: #F7F1E9;
  --color-grey-85: #F4EEE6;
  --color-grey-80: #EFE9E1;
  --color-grey-80-opacity-40: rgba(239, 233, 225, 0.4);
  --color-grey-70: #E7E1D9;
  --color-grey-60: #DED8D0;
  --color-grey-50: #CAC4BC;
  --color-grey-50-opacity-20: rgba(222, 216, 208, 0.2);
  --color-grey-45: #C6C0B8;
  --color-grey-40: #B1ABA3;
  --color-grey-35: #98928A;
  --color-grey-30: #8F8981;
  --color-grey-25: #676159;
  --color-grey-20: #38322A;
  --color-grey-20-opacity-40: rgba(56, 50, 42, 0.4);
  --color-grey-20-opacity-60: rgba(56, 50, 42, 0.6);
  --color-grey-15: #342E26;
  --color-grey-10: #2C261E;
  --color-grey-10-opacity-50: rgba(44, 38, 30, 0.5);
  --color-grey-8: #322C24;
  --color-grey-6: #29231B;
  --color-grey-4: #241E16;

  --color-cool-gray: var(--color-grey-90);

  // Old color names (deprecated)
  --color-brand-darken-4: var(--color-brand-45);
  --color-brand-darken-5: var(--color-brand-40);
  --color-brand-darken-10: var(--color-brand-25);
  --color-brand-darken-12: var(--color-brand-20);

  --color-brand-secondary-darken-5: var(--color-brand-secondary-40);

  --color-light-grey-lighten-5: var(--color-grey-96);
  --color-light-grey-lighten-4: var(--color-grey-93);
  --color-light-grey-lighten-3: var(--color-grey-90);
  --color-light-grey-lighten-2: var(--color-grey-85);
  --color-light-grey: var(--color-grey-80);
  --color-light-grey-opacity-40: var(--color-grey-80-opacity-40);
  --color-2-light-grey: var(--color-grey-70);
  --color-0-med-grey: var(--color-grey-60);
  --color-med-grey: var(--color-grey-50);
  --color-med-grey-opacity-20: var(--color-grey-50-opacity-20);
  --color-2-med-grey: var(--color-grey-45);
  --color-dash-border-grey: var(--color-grey-35);
  --color-drop-down-grey: var(--color-grey-30);
  --color-0-dark-grey: var(--color-grey-25);
  --color-dark-grey: var(--color-grey-20);
  --color-dark-grey-opacity-40: var(--color-grey-20-opacity-40);
  --color-dark-grey-opacity-60: var(--color-grey-20-opacity-60);
  --color-black-grey: var(--color-grey-10);
  --color-black-grey-opacity-50: var(--color-grey-10-opacity-50);
}

@mixin avorenewables-login {
  .map-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-med-grey);
  }

  .login-form {
    width: 350px;
    padding: 20px;
    background-color: var(--color-white);
    color: var(--color-black);
    box-shadow: 0 0 18px 0 var(--color-black-opacity-62);

    label {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
      line-height: 1;
    }

    input[type="text"],
    input[type="password"] {
      font-size: .75rem;
    }
  }

  .login-logo {
    margin: 12px auto 14px;
    background-image: url("/assets/images/avorenewables/nedbank-logo.png");
    background-size: 250px 125px;
    background-repeat: no-repeat;
    height: 125px;
    width: 250px;
  }

  .login-input-container {
    margin-top: 28px;
  }
}

@mixin avorenewables-eula {
  .container-fluid {
    background-color: var(--color-2-med-grey);
  }

  .login-form {
    background-color: var(--color-white);
  }
}

@mixin avorenewables-eula-bg {
  .eula-bg {
    background-color: var(--color-brand);
  }
}

@mixin avorenewables-side-nav {
  .logo-container a {
    height: 50px;

    .navbar-logo-full {
      background-image: url("/assets/images/avorenewables/nedbank-logo.png");
    }

    .navbar-logo-small {
      background-image: url("/assets/images/avorenewables/nedbank-logo.png");
      width: 42px;
      height: 42px;
      margin: auto;
    }
  }

  .switchable-nav {
    height: calc(100vh - 428px) !important;
  }

  .home-link-container {
    .icon {
      path {
        stroke: var(--color-brand);
      }
    }
  }

  .side-navigation {
    ul {
      li {
        a {
          &.plan.selected .icon, &.plan:hover .icon {
            background-color: var(--color-grey-90);
          }
          &.finance.selected .icon, &.finance:hover .icon {
            background-color: var(--color-grey-90);
          }
          &.build.selected .icon, &.build:hover .icon {
            background-color: var(--color-grey-90);
          }
          &.operate.selected .icon, &.operate:hover .icon {
            background-color: var(--color-grey-90);
          }
          &.analytics.selected .icon, &.analytics:hover .icon {
            background-color: var(--color-grey-90);
          }
        }
      }
    }
  }
}

@mixin avorenewables-main-nav {

  .navbar-logo-full {
    width: 89px;
    height: 29px;
    display: block;
    background-size: 89px 29px;
    background-repeat: no-repeat;
    background-image: url("/assets/images/shared/powered-by-odyssey.svg");
  }

  .navbar-brand {
    display: flex;
    align-items: center;
  }

  .navbar-brand:before {
    display: block;
    height: 39px;
    width: 76px;
    background-image: url("/assets/images/avorenewables/nedbank-logo.png");
    background-size: 76px 39px;
    background-repeat: no-repeat;
    content: '';
    float: left;
    margin-right: 32px;
  }

  .main-menu ul {
    a.nav-link {
      color: var(--color-black-grey);

      &:hover {
        color: var(--color-brand);
      }

      &.bold {
        color: var(--color-brand);

        &:hover {
          color: var(--color-brand);
        }
      }
    }
  }

  .nav-link .bold:after{
    content: ' ';
    display: block;
    border-bottom: 3px solid var(--color-brand);
    padding-bottom: 5px;
  }

  .navbar .dropdown-toggle {
    color: var(--color-brand);
  }

  .main-header {
    background-color: var(--color-grey-45) !important;
  }

  .select-profile {
    .dropdown-menu {
      width: 397px;
    }

    .btn-link {
      color: var(--color-black-grey);
    }

    .tab {
      margin-left: 111px;
      margin-right: 0;
    }

    &-noselect {
      .tab {
        margin-left: 0;
      }
    }

    .type-select {
      background-color: var(--color-white);
      background-image: url("/assets/images/shared/powered-by-odyssey.svg");
      border-bottom: 1px solid var(--color-med-grey);
      background-repeat: no-repeat;
      background-position: 188px 24px;
      background-size: 89px 29px;

      &:before {
        display: block;
        height: 39px;
        width: 76px;
        background-image: url("/assets/images/avorenewables/nedbank-logo.png");
        background-size: 76px 39px;
        background-repeat: no-repeat;
        content: '';
        float: left;
        margin-right: 32px;
        opacity: .5;
        filter: grayscale(100%);
      }
    }
  }

  .collapse-menu {
    background-color: var(--color-white);

    .collapse-menu-item {
      color: var(--color-drop-down-grey);
      &::after {
        border-bottom: unset;
        padding: 0;
      }
      &.bold {
        background-color: var(--color-light-grey);
      }
    }
    a.collapse-menu-item {
      text-decoration: none;
    }

    .collapse-menu-item:hover {
      background-color: var(--color-light-grey-lighten-5);
      color: var(--color-dark-grey);
    }

    .nav-help-dropdown:hover {
      background-color: var(--color-light-grey);
    }

    .collapse-menu-item-border {
      border-bottom: 1px solid var(--color-light-grey);
    }
  }

  .animated-icon3 span {
    background: var(--color-brand);
  }

  .profile-collapse-menu {
    .profile-collapse-menu-item {
      color: var(--color-dash-border-grey);
      background-color: var(--color-light-grey-lighten-2);
    }

    .profile-collapse-menu-item:hover {
      color: var(--color-drop-down-grey);
      background-color: var(--color-light-grey);
    }
  }
}

@mixin avorenewables-project-list {
  .project-header {
    background-color: var(--color-dark-grey);
    color: var(--color-white);
  }
  .project-list-header {
    a.back {
      color: var(--color-white) !important;
      &:hover {
        color: var(--color-light-grey) !important;
      }
    }
  }
}

@mixin avorenewables-bulk-upload-icons {
  .project-group-action-body {
    svg {
      rect {
        fill: var(--color-brand);
      }

      polygon {
        fill: var(--color-brand);
      }

      path {
        fill: var(--color-brand);
      }
    }
  }
}

@mixin avorenewables-project-header {
  .update-fin-model-button {
    background-color: var(--color-white);
    color: var(--color-brand);
    border-color: var(--color-white);

    &:hover, &:active {
      background-color: var(--color-white) !important;
      border-color: var(--color-white) !important;
    }

    &:disabled {
      color: var(--color-white) !important;
      background-color: var(--color-med-grey) !important;
      border-color: var(--color-med-grey) !important;
    }
  }
}

@mixin avorenewables-footer {
  footer {
    background-color: var(--color-grey-45);
  }
}

@mixin avorenewables-project-overview {
  .project-overview ::ng-deep {
    .tab-content {
      background-color: unset;
    }

    .overview-content-container {
      background-color: var(--color-white);
    }
  }
}
